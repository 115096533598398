#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
    background: #303945;
}

.sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-weight: bold;
    color: white;
    text-align: center;
}

.version{
    margin-top: -10px;
}

#sidebar-wrapper .nav-ul {
    width: 15rem;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

.list-group-item-action{
    color: #8391a2;
}
.list-group-item-action:hover{
    color: #cee4ff;
}

@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }
}
