body {
    background: #f2f2f2;
    font-size: 0.9rem;
}

.cursor-pointer {
    cursor: pointer;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
    width: 113px !important;
}
