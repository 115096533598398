.imgAckStatusOverlay {
    position: absolute;
    top: 13px;
    left: 5px;
    width: 97.5%;
    height: 93%;
    display: block;
    background: rgba(0, 0, 0, .6);
    text-align: center;
    color: white;
    padding-top: 28%;
}
