/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */
body {
    overflow-x: hidden;
}

.dropdown-menu{
    left: -70px
}

#page-content-wrapper {
    min-width: 100vw;
}

@media (min-width: 768px) {
    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }
}

