.spinner {
    width: 100%;
    position:fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    background: #0000003b;
    z-index: 1;
}

.spinner > div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 50%;
}
